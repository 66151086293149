<template>
  <main class="bg-gray-100 ">

    <!-- Cover Image with Text Banner -->
    <div class="h-screen items-center animate__animated animate__fadeIn">
      <div class=" bg-fixed bg-cover w-full h-full aboutpg">   
        <div class="container m-auto animate__animated animate__fadeIn animate__delay-1s">  
          <div class="items-center absolute bottom-0">
            <h1 class="text-4xl md:text-6xl lg:text-9xl text-center bg-clip-text text-white tracking-wider uppercase">Meet the Team</h1>
            <p class="text-sm md:text-md lg:text-lg text-center font-medium text-white p-1 py-5 rounded-3xl tracking-wide">
              “Qualon” is the best destination where you meet young energetic team who are highly passionate on new treads, creativity and technology to give you the best results.
            </p>
          </div>                                  
        </div>
      </div>           
    </div>

    <!-- Member Area -->
    <div class="bg-fixed py-20">
      <!-- Members -->     
      <div class="m-area">
        <!-- Rashmi Ireshe -->
        <div class="member">
          <picture>
            <source class="m-pic" srcset="../assets/images/team_rashmi.webp" type="image/webp">
            <source class="m-pic" srcset="../assets/images/team_rashmi.jpg" type="image/jpeg"> 
            <img loading="lazy" class="m-pic" src="../assets/images/team_rashmi.jpg" alt="Rashmi Ireshe">
          </picture>        
          <h2>Rashmi Ireshe</h2>  
          <p>CHIEF EXECUTIVE OFFICER</p>
          <div class="container m-auto p-2">
            <ul class="flex justify-evenly items-center w-full">           
              <li><a href="https://facebook.com/rireshe/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/facebook.svg" alt="Facebook" class="w-5 h-5"></a></li>
              <li><a href="https://instagram.com/rireshe/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/instagram.svg" alt="Instagram" class="w-5 h-5"></a></li>
              <li><a href="https://linkedin.com/in/ireshe/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/linkedin.svg" alt="LinkedIn" class="w-5 h-5"></a></li>
            </ul>
          </div>                       
        </div>
        <!-- Yasas Jayaweera -->
        <div class="member">
          <picture>
            <source class="m-pic" srcset="../assets/images/team_yasas.webp" type="image/webp">
            <source class="m-pic" srcset="../assets/images/team_yasas.jpg" type="image/jpeg"> 
             <img loading="lazy" class="m-pic"  src="../assets/images/team_yasas.jpg" alt="Yasas Jayaweera">
          </picture>          
          <h2>Yasas Jayaweera</h2>  
          <p>CHIEF OPERATING OFFICER</p>
          <div class="container m-auto p-2">
            <ul class="flex justify-evenly items-center w-full">           
            <li><a href="https://facebook.com/yasaslakkana.jayaweera.3/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/facebook.svg" alt="Facebook" class="w-5 h-5"></a></li>
            <li><a href="https://instagram.com/yasaslakkana/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/instagram.svg" alt="Instagram" class="w-5 h-5"></a></li>
            <li><a href="https://linkedin.com/in/yasaslakkana/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/linkedin.svg" alt="LinkedIn" class="w-5 h-5"></a></li>
            </ul>
          </div>                           
        </div>        
        <!-- Shasika Subhasinghe -->
        <div class="member">
          <picture>
            <source class="m-pic" srcset="../assets/images/team_shasika.webp" type="image/webp">
            <source class="m-pic" srcset="../assets/images/team_shasika.jpg" type="image/jpeg"> 
             <img loading="lazy" class="m-pic"  src="../assets/images/team_shasika.jpg" alt="Shasika Subhasinghe">
          </picture>              
          <h2>Shasika Subhasinghe</h2> 
          <p>CHIEF TECHNICAL OFFICER</p>
          <div class="container m-auto p-2">
            <ul class="flex justify-evenly items-center w-full">           
            <li><a href="https://facebook.com/ShashDila/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/facebook.svg" alt="Facebook" class="w-5 h-5"></a></li>
            <li><a href="https://instagram.com/shashdila/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/instagram.svg" alt="Instagram" class="w-5 h-5"></a></li>
            <li><a href="https://linkedin.com/in/shashikadilansubasinghe/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/linkedin.svg" alt="LinkedIn" class="w-5 h-5"></a></li>
            </ul>
          </div>                       
        </div>
        <!-- Empty Area for Responsiveness -->
        <div class="hidden lg:block lg:col-span-2"></div>  
        <!-- Himal Malith -->
        <div class="member">
          <picture>
            <source class="m-pic" srcset="../assets/images/team_himal.webp" type="image/webp">
            <source class="m-pic" srcset="../assets/images/team_himal.jpg" type="image/jpeg"> 
             <img loading="lazy" class="m-pic"  src="../assets/images/team_himal.jpg" alt="Himal Malith">
          </picture>              
          <h2>Himal Malith</h2> 
          <p>LEAD WEB DEVELOPER</p>
          <div class="container m-auto p-2">
            <ul class="flex justify-evenly items-center w-full">            
            <li><a href="https://facebook.com/himalmalith" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/facebook.svg" alt="Facebook" class="w-5 h-5"></a></li>
            <li><a href="https://instagram.com/__himal__/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/instagram.svg" alt="Instagram" class="w-5 h-5"></a></li>
            <li><a href="https://linkedin.com/in/himalmd" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/linkedin.svg" alt="LinkedIn" class="w-5 h-5"></a></li>
            </ul>
          </div>                            
        </div>
        <!-- Empty Area for Responsiveness -->
        <div class="hidden md:block md:col-span-3 lg:hidden"></div> 
        <!-- Ishara Susanga -->
        <div class="member">
          <picture>
            <source class="m-pic" srcset="../assets/images/team_ishara.webp" type="image/webp">
            <source class="m-pic" srcset="../assets/images/team_ishara.jpg" type="image/jpeg"> 
             <img loading="lazy" class="m-pic"  src="../assets/images/team_ishara.jpg" alt="Ishara Susanga">
          </picture>           
          <h2>Ishara Susanga</h2> 
          <p>CHIEF DESIGN OFFICER</p>
          <div class="container m-auto p-2">
            <ul class="flex justify-evenly items-center w-full">            
            <li><a href="https://facebook.com/isharasusangadissanayake" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/facebook.svg" alt="Facebook" class="w-5 h-5"></a></li>
            <li><a href="https://instagram.com/ishara_susanga/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/instagram.svg" alt="Instagram" class="w-5 h-5"></a></li>
            <li><a href="https://linkedin.com/in/ishara-dissanayake/" rel="noopener" target="_blank"><img loading="lazy" src="../assets/images/linkedin.svg" alt="LinkedIn" class="w-5 h-5"></a></li>
            </ul>
          </div>                  
        </div>
      </div>  
    </div>
  
    <div class="bg-white">
      <div class="container m-auto pt-28">
        <div class="m-auto justify-center pb-20">    
        <h1 class="text-center text-2xl md:text-5xl font-semibold text-primary">Want to get into the Team?</h1>   
        <p class="text-center text-2xl font-medium py-8 ">We are happy to make our team bigger & bigger with the perfect piece. You might be the one !!!</p> 
        <div class="grid m-auto">
          <span class="text-center uppercase bg-blue-50 rounded-xl p-4 px-6 m-auto w-auto text-primary font-semibold tracking-widest cursor-default">carrers@qualon.xyz</span>
        </div>                   
      </div>               
      </div>
    </div> 

  </main>
</template>